<template>
  <label :class="{ double, answer, 'not-answer': answer !== null && !answer }">
    <input :id="id" :name="name" :type="type" :value="index" :disabled="disabled" :checked="_value" @input="_value = index" />
    <div class="button" :class="{ tall, small, margin }">
      <span v-html="info.name" />
      <img v-if="answer && _value" src="@/assets/check.svg" />
      <img v-else-if="answer !== null && !answer && _value" src="@/assets/warning.svg" />
      <template v-else>
        <div v-if="Array.isArray(info.icon)">
          <img v-for="img in info.icon" :key="img" :src="img" />
        </div>
        <img v-else :src="info.icon" />
      </template>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: str => ['radio', 'checkbox'].includes(str)
    },
    index: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      default: 0
    },
    value: {
      type: Array,
      default: () => []
    },
    answer: {
      type: Boolean,
      default: null
    },
    info: {
      type: Object,
      default: () => ({ name: 'N/A', icon: require('@/assets/Section 07/New Archetype.png') })
    },
    tall: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    margin: {
      type: Boolean,
      default: false
    },
    double: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    id () {
      return `${this.name}_${this.index}`
    },
    disabled () {
      return this.max > 0 && !this._value && this.value.length >= this.max
    },
    _value: {
      get () {
        return this.value.includes(this.index)
      },
      set (value) {
        if (this.type === 'checkbox') {
          if (this.value.includes(value)) {
            this.$emit('input', this.value.filter(x => x !== value))
          } else {
            this.$emit('input', [...this.value, value])
          }
        } else {
          this.$emit('input', [value])
        }
      }
    }
  }
}
</script>

<style scoped>
  .double {
    grid-column: span 2;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    padding-left: 16px;
    padding-right: 8px;
    height: 80px;
    width: 100%;
    background: var(--button);
    text-transform: uppercase;
  }

  .button.tall {
    height: 90px;
  }

  .button.small {
    height: 74px;
  }

  .button.margin {
    padding-left: 12px;
    padding-right: 4px;
  }

  input:not(:disabled) + .button {
    cursor: pointer;
  }

  input:not(:disabled) + .button:hover:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--button-hover);
    opacity: 0.8;
  }

  input:not(:disabled) + .button:hover:after {
    content: '';
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    border: 2px solid var(--button-hover);
  }

  input:checked + .button {
    background: var(--button-checked) !important;
  }

  .answer input:checked + .button {
    background: var(--good-answer) !important;
  }

  .not-answer input:checked + .button {
    background: var(--bad-answer) !important;
  }

  .answer {
    position: relative;
  }

  .answer:after {
    content: '';
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    border: 2px solid var(--good-answer) !important;
    pointer-events: none;
  }

  input {
    display: none;
  }

  span {
    max-width: 76%;
    line-height: 18px;
    letter-spacing: 0;
  }

  img {
    width: 32px;
    margin-right: 8px;
  }

  @media screen and (max-width: 600px) {
    .double {
      grid-column: 1;
    }
  }

  @media screen and (max-width: 400px) {
    .double .button {
      height: auto;
      padding: 10px 16px;
    }
  }
</style>
