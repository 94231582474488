<template>
  <div id="app" class="predictions">
    <header>
      <img class="left" src="@/assets/Left border.png" />
      <img class="right" src="@/assets/Right border.png" />
      <div class="title">
        <img class="radial" src="@/assets/Radial.png" />
        <img class="logo" src="@/assets/Question mark.png" />
        <img class="game" src="@/assets/Main.svg" />
        <img class="cursive" src="@/assets/Predictions.svg" />
        <h1></h1>
      </div>
    </header>
    <aside>
      <div class="results" v-if="isFinished">
        <h2>Results are in!</h2>
        <p>Login or follow the link you shared to get your results back. Every correct guess earns you 10 points. Every wrong guess makes you lose 5.</p>
        <p>What is your score?</p>
      </div>
      <div class="rules" v-else>
        <h2>Rules</h2>
        <p>Cast your votes. Every correct guess earns you 10 points. Every wrong guess makes you lose 5.</p>
        <p>Good luck!</p>
      </div>
      <div class="score">
        <h2>Your score</h2>
        <span class="placeholder">{{ score }}</span>
      </div>
      <div class="buttons">
        <button class="reset" @click="reset">Reset</button>
        <a :href="shareLink" target="_blank" class="share">Share</a>
      </div>
    </aside>
    <main>
      <section>
        <div class="title">
          <h2>Theme of the season</h2>
          <span class="requirement">1 vote max</span>
        </div>
        <div class="grid">
          <app-button v-for="i in range(6)" :key="i" name="theme" type="radio" :index="i" :info="info['theme'][i - 1]" v-model="state['theme']" :answer="answer('theme', i)" />
        </div>
      </section>
      <section>
        <div class="title">
          <h2>Season main characters</h2>
          <span class="requirement">4 votes max</span>
        </div>
        <div class="grid">
          <app-button v-for="i in range(12)" :key="i" tall name="characters" type="checkbox" :max="4" :index="i" :info="info['characters'][i - 1]" v-model="state['characters']" :answer="answer('characters', i)" />
        </div>
      </section>
      <section>
        <div class="title">
          <h2>Season plot points</h2>
          <span class="requirement">3 votes max</span>
        </div>
        <div class="grid">
          <app-button v-for="i in range(12)" :key="i" tall name="plot" type="checkbox" :max="3" :index="i" :info="info['plot'][i - 1]" v-model="state['plot']" :answer="answer('plot', i)" />
        </div>
      </section>
      <section>
        <div class="title">
          <h2>Main locations</h2>
          <span class="requirement">3 votes max</span>
        </div>
        <div class="grid large">
          <app-button v-for="i in range(8)" :key="i" tall name="location" type="checkbox" :max="3" :index="i" :info="info['location'][i - 1]" v-model="state['location']" :answer="answer('location', i)" />
        </div>
      </section>
      <section>
        <div class="title">
          <h2>Season main activites</h2>
          <span class="requirement">3 votes max</span>
        </div>
        <div class="grid">
          <app-button v-for="i in range(12)" :key="i" tall name="activities" type="checkbox" :max="3" :index="i" :info="info['activities'][i - 1]" v-model="state['activities']" :answer="answer('activities', i)" />
        </div>
      </section>
      <section style="margin-top: -15px">
        <div class="title">
          <h2>Reprised raid</h2>
          <span class="requirement">1 vote max</span>
        </div>
        <div class="grid x-large">
          <app-button v-for="i in range(3)" :key="i" name="raid" type="radio" :index="i" :info="info['raid'][i - 1]" v-model="state['raid']" :answer="answer('raid', i)" />
        </div>
      </section>
      <section>
        <div class="title">
          <h2>Season pass exotic archetype</h2>
          <span class="requirement">1 vote max</span>
        </div>
        <div class="grid">
          <app-button v-for="i in range(18)" :key="i" small name="exotic" type="radio" :index="i" :info="info['exotic'][i - 1]" v-model="state['exotic']" :answer="answer('exotic', i)" />
        </div>
      </section>
      <section>
        <div class="title">
          <h2>Reprised D1 weapon(s)</h2>
          <span class="requirement">2 votes max</span>
        </div>
        <div class="grid">
          <app-button v-for="i in range(3)" :key="i" small name="weapon" type="checkbox" :max="2" :index="i" :info="info['weapon'][i - 1]" v-model="state['weapon']" :answer="answer('weapon', i)" />
          <app-button :key="4" small margin name="weapon" type="checkbox" :max="2" :index="4" :info="info['weapon'][3]" v-model="state['weapon']" :answer="answer('weapon', 4)" />
          <app-button v-for="i in range(6)" :key="i + 4" small name="weapon" type="checkbox" :max="2" :index="i + 4" :info="info['weapon'][i + 3]" v-model="state['weapon']" :answer="answer('weapon', i + 4)" />
          <app-button :key="11" small double name="weapon" type="checkbox" :max="2" :index="11" :info="info['weapon'][10]" v-model="state['weapon']" :answer="answer('weapon', 11)" />
        </div>
      </section>
      <section>
        <div class="title">
          <h2>New exotic pieces</h2>
          <span class="requirement">1 vote max per class</span>
        </div>
        <div class="prefixed-grid">
          <span>Warlocks</span>
          <div class="grid large">
            <app-button v-for="i in range(4)" :key="i" name="warlock" type="radio" :index="i" :info="info['armor'][i - 1]" v-model="state['warlock']" :answer="answer('warlock', i)" />
          </div>
          <span>Titans</span>
          <div class="grid large">
            <app-button v-for="i in range(4)" :key="i" name="titan" type="radio" :index="i" :info="info['armor'][i - 1]" v-model="state['titan']" :answer="answer('titan', i)" />
          </div>
          <span>Hunters</span>
          <div class="grid large">
            <app-button v-for="i in range(4)" :key="i" name="hunter" type="radio" :index="i" :info="info['armor'][i - 1]" v-model="state['hunter']" :answer="answer('hunter', i)" />
          </div>
        </div>
      </section>
    </main>
    <div class="end">
      <button v-if="isAuthenticated && !hasPredictionsSaved && !isFinished" class="save" @click="save">Save predictions</button>
      <button v-else-if="!hasPredictionsSaved" class="login" @click="login">Login with Bungie{{ isFinished ? '' : ' to save' }}</button>
      <button v-if="isAuthenticated" class="logout" @click="logout">Logout</button>
    </div>
    <footer>
      <p><span>Prediction brought to you by</span> <a class="alabastro" href="https://twitter.com/freddyalabastro" target="_blank">Alabastro @FreddyAlabastro</a> <span>(design & artwork)</span></p>
      <p class="space">&nbsp;</p>
      <p><span>and</span> <a class="binarmorker" href="https://twitter.com/binarmorker" target="_blank">Binarmorker @Binarmorker</a> <span>(website & coding)</span></p>
    </footer>
  </div>
</template>

<script>
import { Buffer } from 'buffer'
import AppButton from '@/components/AppButton.vue'

const clone = obj => JSON.parse(JSON.stringify(obj))
const defaultState = {
  theme: [],
  characters: [],
  plot: [],
  location: [],
  activities: [],
  raid: [],
  exotic: [],
  weapon: [],
  hunter: [],
  warlock: [],
  titan: [],
  version: 18
}

export default {
  components: {
    AppButton
  },
  data () {
    return {
      state: clone(defaultState),
      isFinished: true,
      hasPredictionsSaved: true,
      isAuthenticated: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth'))?.membership_id : null,
      info: {
        theme: [
          { name: 'Cabal', icon: require('@/assets/Section 01/Cabal.png') },
          { name: 'Vex', icon: require('@/assets/Section 01/Vex.png') },
          { name: 'Fallen', icon: require('@/assets/Section 01/Fallen.png') },
          { name: 'Hive / <br/>Lucent', icon: require('@/assets/Section 01/Hive.png') },
          { name: 'Scorn', icon: require('@/assets/Section 01/Scorn.png') },
          { name: 'Taken', icon: require('@/assets/Section 01/Taken.png') }
        ],
        characters: [
          { name: 'Rasputin / Ana', icon: require('@/assets/Section 02/Bray.png') },
          { name: 'Saint / <br/>Osiris', icon: require('@/assets/Section 02/Saint.png') },
          { name: 'Caiatl / Zavala', icon: require('@/assets/Section 02/Caiatl.png') },
          { name: 'Crow / Amanda', icon: require('@/assets/Section 02/Crow.png') },
          { name: 'Mara / <br/>Petra', icon: require('@/assets/Section 02/Mara.png') },
          { name: 'Saladin / Efrideet', icon: require('@/assets/Section 02/Saladin.png') },
          { name: 'Drifter / Nine', icon: require('@/assets/Section 02/Drifter.png') },
          { name: 'Savathun / Xivu Arath', icon: require('@/assets/Section 02/Xivu.png') },
          { name: 'Eris / Ikora', icon: require('@/assets/Section 02/Ikora.png') },
          { name: 'Mythrax / Variks / Spider', icon: require('@/assets/Section 02/Variks.png') },
          { name: 'Calus / Eramis', icon: require('@/assets/Section 02/Eramis.png') },
          { name: 'Sloane / Asher Mir', icon: require('@/assets/Section 02/Sloane.png') }
        ],
        plot: [
          { name: 'Siva returns / Rasputin<br/> as an exo', icon: require('@/assets/Section 03/Siva.png') },
          { name: 'Xivu Arath arrival', icon: require('@/assets/Section 03/Arrival.png') },
          { name: 'Efrideet returns', icon: require('@/assets/Section 03/Efrideet.png') },
          { name: 'Eramis gets defrosted', icon: require('@/assets/Section 03/Defrosted.png') },
          { name: 'Vex doing Vex things', icon: require('@/assets/Section 03/Vex Things.png') },
          { name: 'Sloane and Asher Mir return', icon: require('@/assets/Section 03/Return.png') },
          { name: 'A key character dies', icon: require('@/assets/Section 03/Death.png') },
          { name: 'DC curse lifted / Baby Ahamkara', icon: require('@/assets/Section 03/Baby Ahamkara.png') },
          { name: 'Eris uncovers a new threat', icon: require('@/assets/Section 03/Eris.png') },
          { name: 'Forges are lit back', icon: require('@/assets/Section 03/Forges.png') },
          { name: 'Savathun gets rezzed', icon: require('@/assets/Section 03/Rezzed.png') },
          { name: 'The Traveler flees', icon: require('@/assets/Section 03/Traveler.png') }
        ],
        location: [
          { name: 'Savathun\'s Throne World', icon: require('@/assets/Section 04/Throneworld.png') },
          { name: 'Europa', icon: require('@/assets/Section 04/Europa.png') },
          { name: 'Nessus', icon: require('@/assets/Section 04/Nessus.png') },
          { name: 'Moon / Leviathan', icon: require('@/assets/Section 04/Moon.png') },
          { name: 'E.D.Z. / Cosmodrome', icon: require('@/assets/Section 04/EDZ.png') },
          { name: 'Eternity', icon: require('@/assets/Section 04/Eternity.png') },
          { name: 'Dreaming City', icon: require('@/assets/Section 04/Dreaming City.png') },
          { name: 'New / Reprised location', icon: require('@/assets/Section 04/Reprised.png') }
        ],
        activities: [
          { name: 'Menagerie style', icon: require('@/assets/Section 05/Menagerie.png') },
          { name: 'Dunk this into that', icon: require('@/assets/Section 05/Dunk.png') },
          { name: 'Riddles & puzzles', icon: require('@/assets/Section 05/Puzzle.png') },
          { name: 'Escalation protocol style', icon: require('@/assets/Section 05/Escalation.png') },
          { name: 'Speedrun / Obstacle course', icon: require('@/assets/Section 05/Speedrun.png') },
          { name: 'Clear / Capture', icon: require('@/assets/Section 05/Capture.png') },
          { name: 'Tower defense', icon: require('@/assets/Section 05/Defence.png') },
          { name: 'Deposit motes', icon: require('@/assets/Section 05/Motes.png') },
          { name: 'Charge artifact', icon: require('@/assets/Section 05/Charge.png') },
          { name: 'Track down / Boss rush', icon: require('@/assets/Section 05/Boss.png') },
          { name: 'Infiltration / Do not get spotted', icon: require('@/assets/Section 05/Infiltration.png') },
          { name: 'Explore & collect', icon: require('@/assets/Section 05/Explore.png') }
        ],
        raid: [
          { name: 'Wrath of the Machine', icon: require('@/assets/Section 06/WOTM.png') },
          { name: 'Crota\'s End', icon: require('@/assets/Section 06/CE.png') },
          { name: 'King\'s Fall', icon: require('@/assets/Section 06/KF.png') }
        ],
        exotic: [
          { name: 'Fusion Rifle', icon: require('@/assets/Section 07/Fusion rifle.png') },
          { name: 'SMG', icon: require('@/assets/Section 07/SMG.png') },
          { name: 'Scout', icon: require('@/assets/Section 07/Recon.png') },
          { name: 'Sword', icon: require('@/assets/Section 07/Sword.png') },
          { name: 'Bow', icon: require('@/assets/Section 07/Bow.png') },
          { name: 'Hand Cannon', icon: require('@/assets/Section 07/HC.png') },
          { name: 'Shotgun', icon: require('@/assets/Section 07/Shotgun.png') },
          { name: 'Glaive', icon: require('@/assets/Section 07/Glaive.png') },
          { name: 'Rocket Launcher', icon: require('@/assets/Section 07/Rocket.png') },
          { name: 'Grenade Launcher', icon: require('@/assets/Section 07/Grenade Launcher.png') },
          { name: 'Trace Rifle', icon: require('@/assets/Section 07/Trace.png') },
          { name: 'Auto Rifle', icon: require('@/assets/Section 07/Auto.png') },
          { name: 'Pulse Rifle', icon: require('@/assets/Section 07/Pulse.png') },
          { name: 'Sniper Rifle', icon: require('@/assets/Section 07/Sniper.png') },
          { name: 'Sidearm', icon: require('@/assets/Section 07/Sidearm.png') },
          { name: 'Machine Gun', icon: require('@/assets/Section 07/Machinegun.png') },
          { name: 'Linear Fusion Rifle', icon: require('@/assets/Section 07/Linear.png') },
          { name: 'New archetype', icon: require('@/assets/Section 07/New Archetype.png') }
        ],
        weapon: [
          { name: 'Touch of Malice', icon: require('@/assets/Section 08/ToM.png') },
          { name: 'Khvostov 7G-0X', icon: require('@/assets/Section 08/Kvhostov.png') },
          { name: 'No Land Beyond', icon: require('@/assets/Section 08/No Land Beyond.png') },
          { name: 'Necrochasm', icon: require('@/assets/Section 08/Nechrocasm.png') },
          { name: 'The Young Wolf\'s Howl', icon: require('@/assets/Section 08/Young Wolf Sword.png') },
          { name: 'Hereafter', icon: require('@/assets/Section 08/Hereafter.png') },
          { name: 'Icebreaker', icon: require('@/assets/Section 08/Icebreaker.png') },
          { name: 'Dreg\'s Promise', icon: require('@/assets/Section 08/Dreg.png') },
          { name: 'Invective', icon: require('@/assets/Section 08/Invective.png') },
          { name: 'Tlaloc', icon: require('@/assets/Section 08/Tlaloc.png') },
          {
            name: 'Raze-Lighter / <br/>Dark-Drinker / <br/>Bolt-Caster',
            icon: [
              require('@/assets/Section 08/Raze.png'),
              require('@/assets/Section 08/Dark.png'),
              require('@/assets/Section 08/Bolt.png')
            ]
          }
        ],
        armor: [
          { name: 'Helmet', icon: require('@/assets/Section 09/Helmet.png') },
          { name: 'Chest Armor', icon: require('@/assets/Section 09/Chest.png') },
          { name: 'Gauntlets', icon: require('@/assets/Section 09/Gauntlets.png') },
          { name: 'Leg Armor', icon: require('@/assets/Section 09/Boots.png') }
        ]
      },
      goodGuesses: {
        theme: [3],
        characters: [7, 10, 11],
        plot: [4],
        location: [2, 3, 5],
        activities: [2, 6, 10],
        raid: [3],
        exotic: [1],
        weapon: [1],
        hunter: [2],
        warlock: [1],
        titan: [3]
      }
    }
  },
  computed: {
    hasPredictions () {
      return Object.values(this.state).some(x => Array.isArray(x) && x.length)
    },
    score () {
      let _score = 0

      Object.keys(this.goodGuesses).forEach(key => {
        _score += this.state[key].reduce((acc, x) => acc + (this.goodGuesses[key].includes(x) ? 10 : -5), 0)
      })

      return _score
    },
    canonical () {
      return this.hasPredictions ? `${location.origin}${location.pathname}${this.encodedState}` : `${location.origin}${location.pathname}`
    },
    encodedState () {
      return '?' + Buffer.from(JSON.stringify(this.state), 'utf8').toString('base64')
    },
    shareLink () {
      const text = this.hasPredictions
        ? 'Here\'s my prediction regarding Season 18 of Destiny 2!'
        : 'Predict with me what the Season 18 of Destiny 2 will be like!'
      return `https://twitter.com/intent/tweet?text=${text.replaceAll(' ', '%20')}&url=${this.canonical}`
    }
  },
  methods: {
    range (a, b = null) {
      return Array.from({ length: b ? b - a : a }, (_, i) => 1 + (b ? b + i : i))
    },
    answer (name, index) {
      return (this.goodGuesses[name] && this.goodGuesses[name].length) ? this.goodGuesses[name].includes(index) : null
    },
    reset () {
      this.state = clone(defaultState)
    },
    login () {
      localStorage.clear()
      localStorage.setItem('s18p', this.encodedState)
      const url = `https://www.bungie.net/en/OAuth/Authorize?client_id=${process.env.VUE_APP_BUNGIE_CLIENT_ID}&response_type=code`
      location.href = url
    },
    logout () {
      localStorage.clear()
      this.hasPredictionsSaved = false
      this.isAuthenticated = null
    },
    async save () {
      const result = await fetch(process.env.VUE_APP_PREDICTION_URL, {
        method: 'PUT',
        headers: {
          membership: this.isAuthenticated,
          season: this.state.version,
          'content-type': 'application/x-www-form-urlencoded'
        },
        body: this.encodedState
      })
      this.hasPredictionsSaved = true
      return result.ok
    }
  },
  async created () {
    try {
      const { version, ...defaultValues } = clone(defaultState)
      let search = location.search ? JSON.parse(Buffer.from(location.search.replace('?', ''), 'base64').toString('utf8')) : {}

      const fetchResult = await (await fetch(process.env.VUE_APP_PREDICTION_URL, {
        headers: {
          membership: this.isAuthenticated,
          season: version
        }
      })).json()

      if (fetchResult.result === '') {
        this.hasPredictionsSaved = false
      } else {
        search = JSON.parse(Buffer.from(fetchResult.result, 'base64').toString('utf8'))
      }

      this.state = Object.assign(defaultValues, search)
      this.state = Object.keys(this.state).reduce((acc, key) => {
        acc[key] = Array.isArray(this.state[key]) ? this.state[key].map(x => +x) : this.state[key]
        return acc
      }, {})

      if (location.search && this.state.version !== 18) {
        switch (this.state.version) {
          default:
            location.replace(`https://predict17.wastedondestiny.com${location.search}`)
            break
        }
      }

      this.state.version = version
    } catch (e) {
      console.log(e)
    }
  },
  watch: {
    state: {
      handler () {
        document.querySelector('link[rel=canonical]').setAttribute('href', this.canonical)
        history.replaceState({}, '', this.hasPredictions ? this.encodedState : location.pathname)
      },
      deep: true
    }
  }
}
</script>

<style>
  @font-face {
    font-family: 'Neue Haas Grotesk';
    src: url('~@/assets/NeueHaasDisplayRoman.ttf');
  }

  @font-face {
    font-family: 'Neue Haas Grotesk Light';
    src: url('~@/assets/NeueHaasDisplayLight.ttf');
  }

  @font-face {
    font-family: 'Neue Haas Grotesk Black';
    src: url('~@/assets/NeueHaasDisplayBlack.ttf');
  }

  :root {
    --outside: rgb(0, 0, 0);
    --button: rgba(0, 0, 0, 0.8);
    --button-hover: rgba(255, 255, 255, 0.2);
    --button-checked: rgba(255, 255, 255, 0.4);
    --title: rgb(41, 41, 41);
    --background: rgb(19, 19, 19);
    --text: rgb(255, 255, 255);
    --padding: 60px;
    --alabastro-gradient: linear-gradient(45deg, rgb(164, 74, 255), rgb(0, 240, 255)) rgb(164, 74, 255);
    --binarmorker-gradient: linear-gradient(45deg, rgb(255, 57, 137), rgb(255, 118, 26)) rgb(255, 57, 137);
    --bad-answer: rgba(255, 0, 0, 0.4);
    --good-answer: rgba(0, 255, 0, 0.4);
  }

  html, body {
    padding: 0;
    margin: 0;
    background: var(--outside);
    color: var(--text);
    font-family: 'Neue Haas Grotesk';
  }

  button {
    border: none;
    outline: none;
  }

  a {
    text-decoration: none;
  }

  .predictions {
    max-width: 1190px;
    width: 100%;
    height: 2946px;
    margin: 0 auto;
    position: relative;
  }

  header {
    position: relative;
    background: linear-gradient(0deg, var(--background) 10%, rgba(0,0,0,0.5) 75%),
                url('~@/assets/Cover_Art.png') calc(50% + 25px) -200px / 1400px no-repeat;
    height: 436px;
  }

  header h1 {
    display: none;
  }

  header > img {
    position: absolute;
    top: 58px;
    height: 325px;
  }

  header > img.left {
    left: 0;
  }

  header > img.right {
    right: 0;
  }

  header .title {
    position: relative;
    top: 210px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  header .title > img {
    position: absolute;
  }

  header .title > img.radial {
    height: 576px;
  }

  header .title > img.logo {
    top: -28px;
    height: 124px;
  }

  header .title > img.game {
    top: -106px;
    height: 48px;
  }

  header .title > img.cursive {
    top: -22px;
    height: 106px;
  }

  aside {
    width: 100%;
    max-width: 1190px;
  }

  aside h2 {
    margin: 0;
    font-size: 23px;
    font-weight: bold;
    font-family: 'Neue Haas Grotesk Black';
    text-transform: uppercase;
    letter-spacing: 0.65px;
  }

  aside .rules {
    position: absolute;
    box-sizing: border-box;
    padding: 18px;
    top: 102px;
    left: 60px;
    width: 196px;
    height: 218px;
    background: var(--button);
  }

  aside .results {
    position: absolute;
    box-sizing: border-box;
    padding: 18px;
    top: 62px;
    left: 60px;
    width: 196px;
    height: 298px;
    background: var(--button);
  }

  aside .rules p,
  aside .results p {
    font-size: 18px;
    font-weight: light;
    margin: 16px 0 0;
  }

  aside .score {
    position: absolute;
    box-sizing: border-box;
    padding: 18px;
    top: 180px;
    right: 60px;
    width: 196px;
    height: 140px;
    background: var(--button);
    text-align: center;
  }

  aside .score .placeholder {
    display: block;
    height: 64px;
    font-size: 52px;
    font-weight: bold;
    font-family: 'Neue Haas Grotesk Black';
    border-bottom: 2px solid var(--text);
  }

  aside .reset {
    position: absolute;
    box-sizing: border-box;
    padding: 18px;
    top: 102px;
    right: 60px;
    width: 96px;
    height: 74px;
    background: var(--button);
    color: var(--text);
    text-align: center;
    cursor: pointer;
    font-size: 23px;
    font-weight: bold;
    font-family: 'Neue Haas Grotesk Black';
    text-transform: uppercase;
    letter-spacing: 0.65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  aside .share {
    position: absolute;
    box-sizing: border-box;
    padding: 18px;
    top: 102px;
    right: 160px;
    width: 96px;
    height: 74px;
    background: var(--button);
    color: var(--text);
    text-align: center;
    cursor: pointer;
    font-size: 23px;
    font-weight: bold;
    font-family: 'Neue Haas Grotesk Black';
    text-transform: uppercase;
    letter-spacing: 0.65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  aside .reset:hover:before,
  aside .share:hover:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--button-hover);
    opacity: 0.8;
  }

  aside .reset:hover:after,
  aside .share:hover:after {
    content: '';
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    cursor: pointer;
    border: 2px solid var(--button-hover);
  }

  section {
    background: var(--background);
    padding-bottom: 30px;
    overflow-x: hidden;
  }

  section .title {
    position: relative;
    background: var(--title);
    height: 106px;
    box-sizing: border-box;
    margin: 0;
    width: calc(100% - var(--padding));
    min-width: 600px;
    clip-path: polygon(100% 0, 100% 0%, calc(100% - 106px) 100%, 0 100%, 0 0);
  }

  section h2 {
    padding: 18px var(--padding) 0;
    margin: 0;
    font-size: 23px;
    font-weight: bold;
    font-family: 'Neue Haas Grotesk Black';
    text-transform: uppercase;
    letter-spacing: 0.65px;
  }

  section .requirement {
    position: absolute;
    top: 10px;
    right: 26px;
    padding: 15px var(--padding) 0;
    font-size: 20px;
    font-family: 'Neue Haas Grotesk Light';
    font-weight: light;
  }

  section .grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 4px;
    margin-top: -42px;
    padding: 0 var(--padding);
  }

  section .grid.large {
    grid-template-columns: repeat(4, 1fr);
  }

  section .grid.x-large {
    grid-template-columns: repeat(3, 1fr);
  }

  section .prefixed-grid {
    position: relative;
    display: grid;
    grid-template-columns: 166px 1fr;
    gap: 4px;
    margin-top: -32px;
    padding: 0 var(--padding);
  }

  section .prefixed-grid > span {
    background: var(--title);
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 20px;
    font-family: 'Neue Haas Grotesk Light';
    font-weight: light;
  }

  section .prefixed-grid > .grid {
    width: 100%;
    margin-top: 0;
    padding: 0;
  }

  .end {
    background: var(--background);
    padding: 15px;
    display: flex;
    justify-content: center;
    gap: 15px;
  }

  .end button {
    box-sizing: border-box;
    padding: 18px;
    height: 74px;
    background: var(--button);
    color: var(--text);
    text-align: center;
    cursor: pointer;
    font-size: 23px;
    font-weight: bold;
    font-family: 'Neue Haas Grotesk Black';
    text-transform: uppercase;
    letter-spacing: 0.65px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .end button:hover:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--button-hover);
    opacity: 0.8;
  }

  .end button:hover:after {
    content: '';
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    cursor: pointer;
    border: 2px solid var(--button-hover);
  }

  footer {
    background: linear-gradient(180deg, var(--background) 10%, rgba(0,0,0,0.75) 75%),
                url('~@/assets/Cover_Art.png') center calc(50% + 28px) / 1200px no-repeat;
    width: 100%;
    height: 142px;
    box-sizing: border-box;
    padding-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    text-align: center;
    padding: 0 var(--padding);
  }

  footer a {
    padding: 0 16px;
    position: relative;
    color: #ffffff;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  footer a:hover:before {
    content: '';
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: 8px;
    right: 8px;
    background: var(--button-hover);
    opacity: 0.8;
  }

  footer a:hover:after {
    content: '';
    position: absolute;
    top: -8px;
    bottom: -8px;
    left: 4px;
    right: 4px;
    cursor: pointer;
    border: 2px solid var(--button-hover);
  }

  footer a.alabastro {
    background: var(--alabastro-gradient);
    background-size: 100%;
    background-clip: text;
  }

  footer a.binarmorker {
    background: var(--binarmorker-gradient);
    background-size: 100%;
    background-clip: text;
  }

  @media screen and (max-width: 1150px) {
    aside {
      display: flex;
      width: 100%;
    }

    aside .rules,
    aside .score {
      position: unset;
      height: auto;
      flex: 1;
    }

    aside .buttons {
      width: 96px;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      justify-content: space-between;
      align-items: center;
    }

    aside .reset,
    aside .share {
      top: unset;
      right: unset;
      position: relative;
      height: 100%;
    }

    section .grid {
      grid-template-columns: repeat(3, 1fr);
    }

    section .prefixed-grid > .grid {
      grid-template-columns: repeat(2, 1fr);
    }

    footer {
      flex-direction: column;
    }

    footer {
      justify-content: center;
      padding: 0;
      padding-bottom: 16px;
    }

    footer .space {
      display: none;
    }

    footer p {
      margin: 8px 0;
    }
  }

  @media screen and (max-width: 800px) {
    section .grid.large {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 700px) {
    aside {
      flex-wrap: wrap;
    }

    aside .buttons {
      width: 100%;
      flex-direction: row;
    }

    aside .reset,
    aside .share {
      width: 100%;
      height: 74px;
    }

    section .title {
      height: 125px;
      clip-path: polygon(100% 0, 100% 0%, calc(100% - 125px) 100%, 0 100%, 0 0);
    }

    section .requirement {
      top: unset;
      right: unset;
      bottom: 50px;
    }

    section .prefixed-grid > .grid {
      grid-template-columns: repeat(1, 1fr);
    }

    footer {
      padding-bottom: 32px;
    }

    footer p {
      display: flex;
      flex-direction: column;
      margin: 0;
    }
  }

  @media screen and (max-width: 600px) {
    :root {
      --padding: 30px;
    }

    header > img {
      top: 103px;
      height: 225px;
    }

    header .title > img.radial {
      height: auto;
      width: 95%;
    }

    header .title > img.logo {
      height: auto;
      width: 20%;
    }

    header .title > img.cursive {
      height: auto;
      width: 75%;
    }

    section .grid,
    section .grid.large,
    section .grid.x-large {
      grid-template-columns: repeat(1, 1fr);
    }

    section .prefixed-grid {
      grid-template-columns: repeat(1, 1fr);
    }

    section .prefixed-grid > span {
      height: 80px;
    }
  }

  @media screen and (max-width: 500px) {
    header {
      height: 336px;
    }

    header > img {
      top: 78px;
      height: 175px;
    }

    header .title {
      top: 160px;
    }

    header .title > img.game {
      top: -86px;
      height: 36px;
    }

    section .title {
      width: 100%;
      height: auto;
      min-width: unset;
      clip-path: unset;
      padding-bottom: 80px;
    }
  }
</style>
